import React, { Component } from 'react';
import {
    CircularProgress,
    IconButton, 
    Button, 
    Tooltip,
    Drawer, 
    Paper, 
    AppBar,
    Typography, 
    Toolbar, 
    TextField,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions,
} from '@material-ui/core/';

import {
    getReportJob,
    deleteReportJob
} from '@apricityhealth/web-common-lib/utils/Services';

import LogsView from '@apricityhealth/web-common-lib/views/LogsView';

import LogsIcon from '@material-ui/icons/List';
import RefreshIcon from '@material-ui/icons/Refresh'
import NavigationClose from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
//import InsertChartIcon from '@material-ui/icons/InsertChart';
import LinkIcon from '@material-ui/icons/Link';

import ReactJson from 'react-json-view';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import User from '@apricityhealth/web-common-lib/components/User';

function isArrayValid(array) {
    return Array.isArray(array) && array.length > 0;
}

function filterContent(filter, report) {
    if (report.reportName && report.reportName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        return true;
    return false;
}


export class ReportJobsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog: null,
            progress: null,
            selectedJob: null,
            filter: "",
            error: null
        }
    }

    componentDidMount() {
        this.loadContent();
    }

    loadContent() {
        const self = this;
        let { appContext } = this.props;
        this.setState({ error: null, progress: <CircularProgress size={20} /> });
        getReportJob(appContext, { jobId: '*', result: true } ).then((batchReports) => {
            this.setState({ batchReports, progress: null });
        }).catch((error) => {
            console.log(`Error `, getErrorMessage(error));
            self.setState({ error: getErrorMessage(error) });
        });
    }

    deleteReport(jobId) {
        const self = this;
        let { appContext } = this.props;
        this.setState({ error: null, progress: <CircularProgress size={20} /> });
        deleteReportJob(appContext, jobId).then(() => {
            this.setState({ dialog: null, progress: null }, self.loadContent.bind(self));
        }).catch((error) => {
            console.log(`Error `, getErrorMessage(error));
            self.setState({ error: getErrorMessage(error) });
        });
    }

    confirmDelete(reportName, jobId) {
        const self = this;
        self.setState({
            dialog: <div>
                <Dialog
                    model="false"
                    open={true}>
                    <DialogTitle>Confirm Delete {reportName} Job</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you SUPER sure you want to delete {reportName} job?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={styles.button} onClick={() => { self.onCloseDialog() }}>Cancel</Button>,
                        <Button variant="contained" style={styles.button} onClick={() => { self.deleteReport(jobId) }}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        });
    }

    showJSONView(s) {
        const self = this;
        let { appContext } = this.props;
        let report = self.table.state.data[s[0]];
        if (report) {
            self.setState({ dialog: <JobView appContext={appContext} report={report} onClose={() => { 
                self.table.setState({ selected: [] }); 
                self.setState({ dialog: null }) 
            }} 
            onDelete={() => { 
                self.table.setState({ selected: [] }); 
                self.confirmDelete(report.reportName, report.jobId) 
            }} /> });
        }
        else {
            self.setState({ dialog: null });
        }
    }

    onCloseDialog() {
        this.setState({ dialog: null });
    }

    getBatchTable() {
        let self = this;
        let { batchReports, filter } = this.state;
        let { appContext } = this.props;
        let columns = [
            { id: 'createdAt', width: 100, label: 'Create Time' },
            { id: "reportName", width: 90, label: "Report Name" },
            { id: "status", width: 100, label: "Status" },
            { id: "startTime", width: 100, label: "Start Time" },
            { id: "endTime", width: 100, label: "End Time" },
            {
                id: 'userId', width: 200, editType: 'label', label: 'User', formatValue: (v) => {
                    return <User appContext={appContext} userId={v} userIdHidden isLabel={true} />;
                }
            },
        ];

        let filtered = [];
        filtered = isArrayValid(batchReports) ? batchReports.filter((r) => (filterContent(filter, r))) : []

        return <EnhancedTable
            disableAdd={true}
            disableMultiSelect={true}
            onTable={table => self.table = table}
            onSelected={(s, t) => { (s.length === 1) && self.showJSONView(s, "ReportJobsView") }}
            onDelete={(data) => { self.deleteReport(data.jobId) }}
            rowsPerPage={25}
            orderBy='createdAt'
            order='desc'
            columnData={columns}
            data={filtered}
            title={`Report Jobs`} />;
    }

    render() {
        const { progress, dialog, filter, error } = this.state;
        const batchReportTable = this.getBatchTable()

        return <table style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td>
                        <TextField style={{ margin: 5, width: 500 }}
                            value={filter} label="Filter" onChange={(event) => { this.setState({ filter: event.target.value }) }} />
                    </td>
                    <td align='right'>
                        {error}
                        <IconButton disabled={progress !== null} onClick={this.loadContent.bind(this)}>{progress ? progress : <RefreshIcon />}</IconButton>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {batchReportTable}
                        {dialog}
                    </td>
                </tr>
            </tbody>
        </table>;
    }
}



class JobView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            error: null,
            dialog: null
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.report !== this.props.report) {
            this.setState({report: this.props.report});
        }
    }

    onDelete() {
        let { report } = this.state;
        if (this.props.onDelete)
            this.props.onDelete(report);
    }
    onClose() {
        if (this.props.onClose)
            this.props.onClose();
    }

    onJobLogs(job) {
        let { appContext } = this.props;
        return <Tooltip key='jobLogs' title='View Logs'><IconButton disabled={!job.requestId} onClick={() => {
            this.setState({
                dialog: <LogsView width={1200} appContext={appContext} requestId={job.requestId} onDone={() => this.setState({ dialog: null })} />
            });
        }}><LogsIcon /></IconButton></Tooltip>;
    }

    render() {
        const self = this;
        let { report, error, dialog } = this.state;
        if (! report ) return null;

        let args = [`reportName=${report.reportName}`, `jobId=${report.jobId}`];
        for (let k in report.args) {
            args.push(`${k}=${report.args[k]}`)
        }

        return <Drawer variant="persistent" anchor="right" open={true}>
            <AppBar style={styles.appBar} position="static">
                <Toolbar>
                    <IconButton onClick={() => self.onClose()}>
                        <NavigationClose />
                    </IconButton>
                    <Typography variant="h6" color="inherit">{report.reportName}</Typography>
                </Toolbar>
            </AppBar>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td valign="top" align="right">
                            <span style={{color: 'red'}}>{error}</span>
                            {this.onJobLogs(report)}
                            <Tooltip title='Open Report'><a href={report.result}><IconButton disabled={!report.result || report.status !== 'done'}><LinkIcon /></IconButton></a></Tooltip>
                            <Tooltip title='Delete Report'><IconButton onClick={() => { self.onDelete() }}><DeleteIcon /></IconButton></Tooltip>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <Paper key="2" style={styles.jsonPaper}>
                                <ReactJson src={report}
                                    collapsed={3} name={report.reportName} collapseStringsAfterLength={64} displayDataTypes={false} />
                            </Paper>
                        </td>
                    </tr>
                </tbody>
            </table>
            {dialog}
        </Drawer>
    }
}


const styles = {
    table: {
        "width": 800
    },
    td: {
        "textAlign": "right"
    },
    appBar: {
        backgroundColor: "#FF9800",
        width: '100%'
    },
    jsonPaper: {
        padding: 10,
        width: "100%",
        borderRadius: 6
    },
    div: {
        textAlign: 'left'
    },
    button: {
        margin: 5
    },

}


export default ReportJobsView;
