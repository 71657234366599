import { useState, useEffect, useMemo } from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    useTheme,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleCardInfo from '@apricityhealth/web-common-lib/components/simpleCardInfo'
import {
    Refresh,
    Code,
} from '@material-ui/icons';

import T from 'i18n-react';
import Chart from 'react-apexcharts'
import Moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { DataGrid } from '@mui/x-data-grid';
import Page from '@apricityhealth/web-common-lib/pages/page'
import { InfoCard, InfoCardGrid, InfoCardBanner, InfoCardTitle, InfoCardHeader } from '@apricityhealth/web-common-lib/components/infoCard'
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import Patient from '@apricityhealth/web-common-lib/components/Patient';
import Org from '@apricityhealth/web-common-lib/components/Org';

import {
    getReportJob,
    saveReportJob
} from '@apricityhealth/web-common-lib/utils/Services'

import ProgressText from '@apricityhealth/web-common-lib/components/ProgressText'
import Config from '@apricityhealth/web-common-lib/Config';
import styles from './StatisticsPage.styles'
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;
const REPORT_NAME = STAGE === "production" ? "RTWStatistics" : "VCCStatistics";
const isRTW = STAGE === "production"
const PATIENT_TEXT = STAGE === "production" ? "Employees" : "Patients";

const dateFormat = 'llll';

const LANGUAGES = require('@apricityhealth/web-common-lib/config/languages.json');


function findDevice(agent) {
    if (agent.indexOf("Macintosh") >= 0) return "Macintosh";
    if (agent.indexOf("iPhone") >= 0) return "iPhone";
    if (agent.indexOf("iPad") >= 0) return "iPad";
    if (agent.indexOf("Android") >= 0) return "Android";
    if (agent.indexOf("Windows") >= 0) return "Windows";
    if (agent.indexOf("PostmanRuntime") >= 0) return "Postman";
    if (agent.indexOf("insomnia") >= 0) return "Insomnia";
    if (agent.indexOf("X11") >= 0) return "Windows";
    if (agent.indexOf("unknown-agent") >= 0) return "unknown";
    return agent;
}

function seperate(array, char) {
    return array.length ? array.map((item, index) => <span>{item}{index < array.length - 1 ? <span>{char}&nbsp;</span> : ""}</span>) : ", ";
}
/**
 * Page that displays aggregate data and some charts.
 */
function StatisticsPage({ classes, appContext }) {
    const theme = useTheme();
    const [fetching, setFetching] = useState(false);
    const [currentJob, setCurrent] = useState(null);
    const [mostRecentCompletedJob, setMostRecentCompletedJob] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [jobResult, setJobResult] = useState(null);
    const [checkInDays, setCheckInDays] = useState(365);
    const [jsonViewObject, setJSONViewObject] = useState({});


    useEffect(() => {
        internalFetchJobs();

    }, [])

    async function startNewJob() {
        let job = {
            reportName: REPORT_NAME,
            recipe: 'json',
            args: {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            isSeries: true
        }
        await saveReportJob(appContext, job);
        internalFetchJobs(); // fetch the list
    }


    async function internalFetchJobs() {
        setFetching(true);
        setCurrent(null);
        setJobResult(null);
        setMostRecentCompletedJob(null)
        try {
            let jobs = await getReportJob(appContext, { jobId: '*', result: true, limit: 10, reportName: REPORT_NAME } )
            console.debug(`Jobs`, jobs)

            if (jobs && jobs.length > 0) {
                let currentJob = jobs[0];
                setCurrent(currentJob);

                let recentCompletedJob = jobs.find((job) => job.status === 'done');
                console.debug(`recentCompletedJob`, recentCompletedJob)
                if (recentCompletedJob) {
                    setMostRecentCompletedJob(recentCompletedJob)
                    try {
                        if (typeof recentCompletedJob.result === 'string' && (recentCompletedJob.result.indexOf('https://') === 0)) {
                            let s3 = await fetch(recentCompletedJob.result);
                            let result = await s3.json();
                            setJobResult(result);
                        }
                    } catch (error) {
                        console.error(`error fetching job result`)
                    }
                }
            }
        } catch (error) {
            console.error(`error fetching batch report data`)
        }
        setFetching(false)
    }


    const checkInDistributionChart = useMemo(() => {
        let term = `checkinByHourCounts${checkInDays}Days`;
        let checkInByHour = (jobResult && jobResult[term]) || {}
        //normalize
        for (let index = 0; index < 24; index++) {
            const element = checkInByHour[index];
            if (!element) checkInByHour[index] = 0
        }
        let values = checkInByHour ? Object.values(checkInByHour) : null;
        let sum = isArrayValid(values) ? values.reduce((a, b) => a + b) : 0
        let databar = [{ name: "count", data: values }];
        let categories = Object.keys(checkInByHour).map((hour) => Moment().set('hour', hour).format("h a"));
        const options = {
            theme: theme.overrides?.ApexChart,
            subtitle: {
                text: `Total: ${sum}`,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#9699a2'
                },
            },
            chart: {
                id: 'checkinByHourCounts',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on',
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
            colors: ["#00838f", "#00695c"]
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, checkInDays, theme])

    const newPatientsByMonthChart = useMemo(() => {
        let term = `newPatientCountsByMonth`;
        let newPatientsByMonth = (jobResult && jobResult[term]) || {}

        let values = [];
        let categories = [];
        let sum = 0;
        //normalize
        for (let index = 0; index < 12; index++) {
            let month = Moment().subtract(index, 'months').format("MMM YY");
            let element = newPatientsByMonth[month];
            if (!element) {
                element = 0;
                newPatientsByMonth[month] = element;
            }
            values.unshift(element);
            categories.unshift(month);
            sum += element;
        }
        let databar = [{ name: "count", data: values }];
        const options = {
            theme: theme.overrides?.ApexChart,
            subtitle: {
                text: `Total: ${sum}`,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#9699a2'
                },
            },
            chart: {
                id: 'newPatientCountsByMonth',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on',
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
            colors: ["#00838f", "#00695c"]
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    const patientTriageCountsByMonthChart = useMemo(() => {
        if (isRTW) return null;
        let term = `patientTriageCountsByMonth`;
        let newPatientsByMonth = (jobResult && jobResult[term]) || {}

        let values = [];
        let categories = [];
        let sum = 0;
        //normalize
        for (let index = 0; index < 12; index++) {
            let month = Moment().subtract(index, 'months').format("MMM YY");
            let element = newPatientsByMonth[month];
            if (!element) {
                element = 0;
                newPatientsByMonth[month] = element;
            }
            values.unshift(element);
            categories.unshift(month);
            sum += element;
        }
        let databar = [{ name: "count", data: values }];
        const options = {
            theme: theme.overrides?.ApexChart,
            subtitle: {
                text: `Total: ${sum}`,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#9699a2'
                },
            },
            chart: {
                id: 'patientTriageCountsByMonthChart',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on',
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
            colors: ["#00838f", "#00695c"]
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    const orgChart = useMemo(() => {
        let orgs = jobResult?.orgs || [];
        orgs = orgs.filter(o => o.patientCount > 0).sort((a, b) => b.patientCount - a.patientCount);
        let databar = [
            { name: "Total", data: orgs.map((org) => org.patientCount) },
            { name: "Confirmed", data: orgs.map((org) => org.patientCountWithConfirmedAccount) },
            { name: "At least one check-in ", data: orgs.map((org) => org.patientCountWithAtLeastOneCheckin) },
            { name: "At least one check-in (30 days)", data: orgs.map((org) => org.patientCountWithAtLeastOneCheckin30Days) },

        ];
        let categories = orgs ? orgs.map((org) => org.name) : [];
        const options = {
            theme: theme.overrides?.ApexChart,
            chart: {
                type: 'bar',
                id: 'apexchart',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    horizontal: false,
                    columnWidth: '30%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on'
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    const selfRegisterOrgChart = useMemo(() => {
        let registerCounts = jobResult?.selfRegisterCountsByOrg || [];
        let orgs = jobResult?.orgs || [];

        let databar = [
            { name: "Total", data: Object.entries(registerCounts).map(([key, value]) => value) },
        ];
        let categories = Object.entries(registerCounts).map(([key]) => {
            let org = orgs.find(org => org.orgId === key);
            return org ? org.name : key;
        }
        );
        const options = {
            theme: theme.overrides?.ApexChart,
            chart: {
                type: 'bar',
                id: 'apexchart',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    horizontal: false,
                    columnWidth: '30%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on'
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    const loginByDeviceChart = useMemo(() => {
        let term = `deviceLoginCounts`;
        let deviceLoginCounts = (jobResult && jobResult[term]) || {};
        let entries = Object.entries(deviceLoginCounts);
        let devices = [];
        for (let index = 0; index < entries.length; index++) {
            const element = entries[index];
            let shortName = findDevice(element[0]);
            let device = devices.find(d => d[0] === shortName);
            if (!device) {
                device = [shortName, element[1]];
                devices.push(device);
            } else {
                device[1] += element[1];
            }
        }
        devices = devices.sort((a, b) => b[1] - a[1])

        let values = deviceLoginCounts ? devices.map(e => e[1]) : null;
        let sum = isArrayValid(values) ? values.reduce((a, b) => a + b) : 0
        let databar = [{ name: "count", data: values }];
        let categories = deviceLoginCounts ? devices.map(e => e[0]) : null;

        const options = {
            theme: theme.overrides?.ApexChart,
            subtitle: {
                text: `Total: ${sum}`,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#9699a2'
                },
            },
            chart: {
                id: 'deviceLoginCounts',
                type: 'bar',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on',
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                colors: ['#C3B1E1'],
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    const duplicateList = useMemo(() => {
        const columns = [
            {
                field: 'patientId', headerName: <b>{PATIENT_TEXT}</b>, width: 300, renderCell: (params) => {
                    let v = params.value;
                    return <Patient patientId={v} emailOnly={true} />
                }
            },
            {
                field: 'orgs', headerName: <b>Orgs</b>, width: 400, renderCell: (params) => {
                    let v = params.value;
                    let orgNames = isArrayValid(v) ? v.map((org) => <Org appContext={appContext} orgId={org.systemName.slice(4)} />) : "";
                    return seperate(orgNames, ";"); //some names have commas
                }
            }
        ]
        let patientsWithMultipleOrgs = (jobResult && jobResult.patientsWithMultipleOrgs) || [];

        patientsWithMultipleOrgs.forEach(element => {
            element.id = element.patientId;
        });

        let list = <DataGrid
            className={classes.table}
            pagination
            density="compact"
            rowHeight={50}
            pageSize={10}
            style={{ backgroundColor: theme.palette.background.paper }}
            rowsPerPageOptions={[10, 25, 50]}
            rows={patientsWithMultipleOrgs}
            columns={columns}
            onRowClick={({ row }) => {
                setJSONViewObject(row);
                setShowCode(true);
            }}
        />;

        return list;
    }, [jobResult, theme])

    const languageTable = useMemo(() => {
        return jobResult?.languageCounts ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {Object.entries(jobResult?.languageCounts).map(([languageCode, count]) =>
                        <TableRow><TableCell >{LANGUAGES[languageCode] || languageCode}</TableCell><TableCell>{count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);


    let timeZone = jobResult?.args?.timeZone || 'America/Chicago';
    let metricsDuration = jobResult?.oldestMetric ? Moment().diff(Moment(jobResult?.oldestMetric.createdAt), 'days') : "~30"

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <div className={classes.left}>
                    <div>
                        {!jobResult && currentJob?.status !== "active" && !fetching && <Typography color="primary" variant="subtitle1">
                            {T.translate("noRecentMetrics")}
                        </Typography>
                        }
                        {mostRecentCompletedJob && <Typography color="primary" variant="subtitle1">
                            {T.translate("metricsAsOf")} {Moment(mostRecentCompletedJob.endTime).format(dateFormat)}
                        </Typography>
                        }
                        {currentJob?.status === "active" && <div style={{ marginLeft: "1rem" }}><ProgressText text={"updating"} /></div>
                        }
                    </div>
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={fetching}
                        onClick={() => startNewJob()}>
                        <T.span text="regenerate" />
                    </Button>
                    <IconButton
                        color="primary"
                        disabled={fetching}
                        onClick={() => {
                            setJSONViewObject({ jobResult, currentJob });
                            setShowCode(true);
                        }
                        }>
                        <Code />
                    </IconButton>
                    <IconButton
                        color="primary"
                        style={{ marginRight: '1rem' }}
                        disabled={fetching}
                        onClick={() => { internalFetchJobs() }}>
                        {fetching ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <div>
                <Page  >
                    {jobResult &&
                        <InfoCardGrid >

                            <InfoCard size="xl">
                                <InfoCardBanner>
                                    <Typography variant="h6"> ({Moment().tz(timeZone).format('z')})</Typography>
                                    <InfoCardTitle>Check-In 24 Hour Distribution</InfoCardTitle>
                                    <Select value={checkInDays}
                                        onChange={(e) => setCheckInDays(e.target.value)}
                                        style={{ textAlign: 'left', width: 100 }}
                                    >
                                        <MenuItem value={7}>1 Week</MenuItem>
                                        <MenuItem value={30}>1 Month</MenuItem>
                                        <MenuItem value={180}>6 Months</MenuItem>
                                        <MenuItem value={365}>1 Year</MenuItem>
                                    </Select>
                                </InfoCardBanner>
                                {checkInDistributionChart}
                            </InfoCard>

                            <InfoCard size="xl">
                                <InfoCardBanner>
                                    <div>All orgs</div>
                                    <InfoCardTitle>New {PATIENT_TEXT} by Month <br /><i><font size="2">(patient records created)</font></i></InfoCardTitle>
                                    <div></div>
                                </InfoCardBanner>
                                {newPatientsByMonthChart}
                            </InfoCard>

                            {!isRTW && <InfoCard size="xl">
                                <InfoCardBanner>
                                    <div>All orgs</div>
                                    <InfoCardTitle>{PATIENT_TEXT} Triaged Counts by Month</InfoCardTitle>
                                    <div></div>
                                </InfoCardBanner>
                                {patientTriageCountsByMonthChart}
                            </InfoCard>}

                            <InfoCard size="xl">
                                <InfoCardHeader>
                                    <InfoCardTitle>{PATIENT_TEXT} count per organization {isRTW ? " (Registered)" : ""} </InfoCardTitle>
                                </InfoCardHeader>
                                {orgChart}
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={languageTable}
                                    text={`Language Counts`}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-language" />}
                                    iconColor='teal'
                                />
                            </InfoCard>

                            <InfoCard size="xl">
                                <InfoCardBanner>
                                    <div></div>
                                    <InfoCardTitle>Self Register per organization (~30 days)</InfoCardTitle>
                                    <div><IconButton
                                        color="primary"
                                        disabled={fetching}
                                        onClick={() => {
                                            setJSONViewObject((jobResult && jobResult.selfRegisterMetrics) || []);
                                            setShowCode(true);
                                        }}>
                                        <Code />
                                    </IconButton></div>
                                </InfoCardBanner>
                                {selfRegisterOrgChart}
                            </InfoCard>


                            <InfoCard size="md">
                                <InfoCardBanner>
                                    <div>{metricsDuration} days</div>
                                    <InfoCardTitle>Device login count</InfoCardTitle>
                                    <div> <IconButton
                                        color="primary"
                                        disabled={fetching}
                                        onClick={() => {
                                            setJSONViewObject((jobResult && jobResult.deviceLoginCounts) || {});
                                            setShowCode(true);
                                        }}>
                                        <Code />
                                    </IconButton></div>
                                </InfoCardBanner>
                                {loginByDeviceChart}
                            </InfoCard>

                            <InfoCard size="md">
                                <InfoCardBanner>
                                    <div></div>
                                    <InfoCardTitle>{PATIENT_TEXT} - multiple orgs</InfoCardTitle>
                                    <div> <IconButton
                                        color="primary"
                                        disabled={fetching}
                                        onClick={() => {
                                            setJSONViewObject((jobResult && jobResult.patientsWithMultipleOrgs) || []);
                                            setShowCode(true);
                                        }}>
                                        <Code />
                                    </IconButton></div>
                                </InfoCardBanner>
                                {duplicateList}
                            </InfoCard>

                        </InfoCardGrid>
                    }
                </Page>
            </div>
            <Dialog
                model="true"
                maxWidth={'md'}
                fullWidth={true}
                open={showCode}
                onClose={() => setShowCode(false)}
            >

                <DialogTitle>Statistics</DialogTitle>
                <DialogContent>
                    <Paper key="2" className={classes.jsonPaper}>
                        <ReactJson
                            src={jsonViewObject}
                            theme={theme?.overrides?.ReactJsonView}
                            collapsed={3}
                            name="json"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default withStyles(styles)(StatisticsPage)
