import { useState, useEffect, useMemo } from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
} from '@material-ui/core';
import {
    Refresh,
    Hotel,
    Group,
    AccountBalance,
    QuestionAnswer,
    HowToReg,
    Code,
    History,
    FileCopy,
    Notifications
} from '@material-ui/icons';

import T from 'i18n-react';
import Chart from 'react-apexcharts'
import Moment from 'moment-timezone';
import ReactJson from 'react-json-view';

import Page from '@apricityhealth/web-common-lib/pages/page'
import { InfoCard, InfoCardGrid, InfoCardHeader, InfoCardTitle } from '@apricityhealth/web-common-lib/components/infoCard'
import SimpleCardInfo from '@apricityhealth/web-common-lib/components/simpleCardInfo'
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import Config from '@apricityhealth/web-common-lib/Config';
import ProgressText from '@apricityhealth/web-common-lib/components/ProgressText'
import {
    getReportJob,
    saveReportJob
} from '@apricityhealth/web-common-lib/utils/Services'

import styles from './OverviewPage.styles'

const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;
const REPORT_NAME = STAGE === "production" ? "RTWOverviewMetrics" : "VCCOverviewMetrics";
const PATIENT_TEXT = STAGE === "production" ? "Employees" : "Patients";
const PROVIDER_TEXT = STAGE === "production" ? "Managers" : "Providers";
const dateFormat = 'llll';
const isRTW = STAGE === "production"

/**
 * Page that displays aggregate data and some charts.
 */
function OverviewPage({ classes, appContext }) {
    const theme = useTheme();
    const [fetching, setFetching] = useState(false);
    const [currentJob, setCurrent] = useState(null);
    const [mostRecentCompletedJob, setMostRecentCompletedJob] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [jobResult, setJobResult] = useState(null);


    useEffect(() => {
        internalFetchJobs();
    }, [])


    async function startNewJob() {
        let job = {
            reportName: REPORT_NAME,
            recipe: 'json',
            isSeries: true
        }
        await saveReportJob(appContext, job);
        internalFetchJobs(); // fetch the list
    }

    async function internalFetchJobs() {
        setFetching(true);
        setCurrent(null);
        setJobResult(null);
        setMostRecentCompletedJob(null)
        try {
            let jobs = await getReportJob(appContext, { jobId: '*', result: true, limit: 10, reportName: REPORT_NAME } )
            console.debug(`Jobs`, jobs)

            if (jobs && jobs.length > 0) {
                let currentJob = jobs[0];
                setCurrent(currentJob);

                let recentCompletedJob = jobs.find((job) => job.status === 'done');
                console.debug(`recentCompletedJob`, recentCompletedJob)
                if (recentCompletedJob) {
                    setMostRecentCompletedJob(recentCompletedJob)
                    try {
                        if (typeof recentCompletedJob.result === 'string' && (recentCompletedJob.result.indexOf('https://') === 0)) {
                            let s3 = await fetch(recentCompletedJob.result);
                            let result = await s3.json();
                            setJobResult(result);
                        }
                    } catch (error) {
                        console.error(`error fetching job result`)
                    }
                }
            }
        } catch (error) {
            console.error(`error fetching batch report data`)
        }
        setFetching(false)
    }

    const activeChart = useMemo(() => {
        return <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    <TableRow><TableCell>Inactive {PATIENT_TEXT}</TableCell><TableCell>{Number(jobResult?.inactivePatientCount).toLocaleString()}</TableCell></TableRow >
                    <TableRow><TableCell>Deleted {PATIENT_TEXT}</TableCell><TableCell>{Number(jobResult?.deletedPatientCount).toLocaleString()}</TableCell></TableRow >
                </TableBody>
            </Table>
        </TableContainer>

    }, [jobResult, theme]);

    const checkInChart = useMemo(() => {
        return <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    <TableRow ><TableCell>24 Hours</TableCell><TableCell>{Number(jobResult?.totalPatientsCheckIn24Hours).toLocaleString()}</TableCell></TableRow >
                    <TableRow><TableCell>30 Days</TableCell><TableCell>{Number(jobResult?.totalPatientsCheckIn30Days).toLocaleString()}</TableCell></TableRow >
                </TableBody>
            </Table>
        </TableContainer>

    }, [jobResult, theme]);

    const registeredChart = useMemo(() => {
        return <TableContainer>
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    <TableRow><TableCell>24 Hours</TableCell><TableCell>{Number(jobResult?.totalPatientsRegistered24Hours).toLocaleString()}</TableCell></TableRow >
                    <TableRow><TableCell>30 Days</TableCell><TableCell>{Number(jobResult?.totalPatientsCheckIn30Days).toLocaleString()}</TableCell></TableRow >
                </TableBody>
            </Table>
        </TableContainer>

    }, [jobResult, theme]);

    const labAlertCountsChart = useMemo(() => {
        return jobResult?.alertCounts24Hours?.labAlertCounts?.length > 0 ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.alertCounts24Hours?.labAlertCounts.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);

    const checkInAlertCountsChart = useMemo(() => {
        return jobResult?.alertCounts24Hours?.checkInAlertCounts?.length > 0 ? <TableContainer >
            < Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.alertCounts24Hours?.checkInAlertCounts.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table >
        </TableContainer > : <div>None</div>;


    }, [jobResult, theme]);



    const orgChart = useMemo(() => {
        let orgs = jobResult?.orgs || [];
        orgs = orgs.sort((a, b) => b.patientCount - a.patientCount);
        let databar = [{ name: "count", data: orgs.map((org) => org.patientCount) }];
        let categories = orgs ? orgs.map((org) => org.name) : [];
        const options = {
            theme: theme.overrides?.ApexChart,
            chart: {
                id: 'apexchart',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
                categories,
                tickPlacement: 'on'
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
            colors: ["#00838f", "#00695c"]
        }

        return <Chart options={options} series={databar} type="bar" height="400" style={{ width: "100%" }} />

    }, [jobResult, theme])

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <div className={classes.left}>
                    <div>
                        {!jobResult && currentJob?.status !== "active" && !fetching && <Typography color="primary" variant="subtitle1">
                            {T.translate("noRecentMetrics")}
                        </Typography>
                        }
                        {mostRecentCompletedJob && <Typography color="primary" variant="subtitle1">
                            {T.translate("metricsAsOf")} {Moment(mostRecentCompletedJob.endTime).format(dateFormat)}
                        </Typography>
                        }
                        {currentJob?.status === "active" && <div style={{ marginLeft: "1rem" }}><ProgressText text={"updating"} /></div>
                        }
                    </div>
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={fetching}
                        onClick={() => startNewJob()}>
                        <T.span text="regenerate" />
                    </Button>
                    <IconButton
                        color="primary"
                        disabled={fetching}
                        onClick={() => setShowCode(true)}>
                        <Code />
                    </IconButton>
                    <IconButton
                        color="primary"
                        style={{ marginRight: '1rem' }}
                        disabled={fetching}
                        onClick={() => { internalFetchJobs() }}>
                        {fetching ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <div>
                <Page  >
                    {jobResult &&
                        <InfoCardGrid >
                            <InfoCard size="sm" >
                                <SimpleCardInfo
                                    data={jobResult.totalPatients}
                                    text={`Total ${PATIENT_TEXT} ${isRTW ? " (Registered)" : ""}`}
                                    icon={<Hotel />}
                                    iconColor='violet'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={jobResult.totalProviders}
                                    text={`Total ${PROVIDER_TEXT}`}
                                    icon={<Group />}
                                    iconColor='green'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={jobResult.totalOrganizations}
                                    text="Total Organizations"
                                    icon={<AccountBalance />}
                                    iconColor='skyblue'
                                />
                            </InfoCard>

                            {jobResult?.totalRosterItems && <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={Number(jobResult.totalRosterItems).toLocaleString()}
                                    text="Total Roster"
                                    icon={<Group />}
                                    iconColor='skyblue'
                                />
                            </InfoCard>}

                            {jobResult?.totalDuplicatesInRoster && <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={jobResult.totalDuplicatesInRoster}
                                    text="Total Roster Duplicates"
                                    icon={<FileCopy />}
                                    iconColor='skyblue'
                                />
                            </InfoCard>}

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={registeredChart}
                                    text="Registered Counts"
                                    icon={<HowToReg />}
                                    iconColor='teal'
                                />
                            </InfoCard>


                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={checkInChart}
                                    text="Check-in Counts"
                                    icon={<QuestionAnswer />}
                                    iconColor='indigo'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={activeChart}
                                    text={`Miscellaneous Counts`}
                                    icon={<History />}
                                    iconColor='teal'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={checkInAlertCountsChart}
                                    text={`24 Hour Check-In Alert Counts`}
                                    icon={<Notifications />}
                                    iconColor='red'
                                />
                            </InfoCard>

                            {!isRTW && <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={labAlertCountsChart}
                                    text={`24 Hour Lab Alert Counts`}
                                    icon={<Notifications />}
                                    iconColor='orange'
                                />
                            </InfoCard>}

                            <InfoCard size="xl">
                                <InfoCardHeader>
                                    <InfoCardTitle>{PATIENT_TEXT} count per organization {isRTW ? " (Registered)" : ""} </InfoCardTitle>
                                </InfoCardHeader>
                                {orgChart}
                            </InfoCard>

                        </InfoCardGrid>
                    }
                </Page>
            </div>
            <Dialog
                model="true"
                maxWidth={'md'}
                fullWidth={true}
                open={showCode}
                onClose={() => setShowCode(false)}
            >

                <DialogTitle>Overview Metrics</DialogTitle>
                <DialogContent>
                    <Paper key="2" className={classes.jsonPaper}>
                        <ReactJson
                            src={{ jobResult, currentJob }}
                            theme={theme?.overrides?.ReactJsonView}
                            collapsed={3}
                            name="Metrics"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default withStyles(styles)(OverviewPage)
