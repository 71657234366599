import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    withStyles,
    Typography,
    IconButton,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select,
} from '@material-ui/core';
import {
    Refresh,
    Code,
} from '@material-ui/icons';
import Chart from 'react-apexcharts'
import { get, orderBy } from 'lodash';
import T from 'i18n-react';
import moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Page from '@apricityhealth/web-common-lib/pages/page'
import { InfoCard, InfoCardGrid, InfoCardBanner, InfoCardTitle } from '@apricityhealth/web-common-lib/components/infoCard'
import SimpleCardInfo from '@apricityhealth/web-common-lib/components/simpleCardInfo'
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import Config from '@apricityhealth/web-common-lib/Config';
import ProgressText from '@apricityhealth/web-common-lib/components/ProgressText'
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import {
    getReportJob,
    saveReportJob,
    isArrayValid
} from '@apricityhealth/web-common-lib/utils/Services'

import styles from './LongitudinalPage.styles'

const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;
const REPORT_NAME = "LongitudinalMetrics";
const PATIENT_TEXT = STAGE === "production" ? "Employees" : "Patients";
const PROVIDER_TEXT = STAGE === "production" ? "Managers" : "Providers";
const dateFormat = 'llll';
const isRTW = STAGE === "production"
const DISPOSITION_CATEGORIES = [
    "provider.patient.disposition.contacted",
    "provider.patient.disposition.contacted.addition",
    "provider.patient.disposition.notcontacted",
    "provider.patient.disposition.notcontacted.addition"
]

/**
 * Page that displays aggregate data and some charts.
 */
function LongitudinalPage({ classes, appContext }) {

    const theme = useTheme();
    const textContent = useSelector(state => state.app.textContent);
    const language = useSelector(state => state.app.language);
    const [fetching, setFetching] = useState(false);
    const [currentJob, setCurrent] = useState(null);
    const [dispositionAlertLevel, setDispositionAlertLevel] = useState("all");
    const [dispositionAlertLevels, setDispositionAlertLevels] = useState(["all", "red", "orange", "yellow", "green"]);
    const [returnData, setReturnData] = useState(false);
    const [mostRecentCompletedJob, setMostRecentCompletedJob] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [jobResult, setJobResult] = useState(null);
    const [startTime, setStartTime] = useState(jobResult?.args?.startTime || moment().subtract(1, "year").startOf("day").toDate());
    const [endTime, setEndTime] = useState(jobResult?.args?.startTime || moment().endOf("day").toDate());


    useEffect(() => {
        internalFetchJobs();
    }, [])


    async function startNewJob() {
        let job = {
            reportName: REPORT_NAME,
            recipe: 'json',
            isSeries: true,
            args: {
                startTime,
                endTime,
                returnData
            }
        }
        if (isRTW)
            job.includeRTWOrgs = true;

        await saveReportJob(appContext, job);
        internalFetchJobs(); // fetch the list
    }

    async function internalFetchJobs() {
        setFetching(true);
        setCurrent(null);
        setJobResult(null);
        setMostRecentCompletedJob(null)
        try {
            let jobs = await getReportJob(appContext, { jobId: '*', result: true, limit: 10, reportName: REPORT_NAME })
            console.debug(`Jobs`, jobs)

            if (jobs && jobs.length > 0) {
                let currentJob = jobs[0];
                setCurrent(currentJob);

                let recentCompletedJob = jobs.find((job) => job.status === 'done');
                console.debug(`recentCompletedJob`, recentCompletedJob)
                if (recentCompletedJob) {
                    setMostRecentCompletedJob(recentCompletedJob)
                    try {
                        if (typeof recentCompletedJob.result === 'string' && (recentCompletedJob.result.indexOf('https://') === 0)) {
                            let s3 = await fetch(recentCompletedJob.result);
                            let result = await s3.json();
                            // console.debug(`job result `, result)
                            if (jobResult?.args?.startTime)
                                setStartTime(jobResult?.args?.startTime);
                            if (jobResult?.args?.endTime)
                                setEndTime(jobResult?.args?.endTime);
                            if (jobResult?.args?.returnData)
                                setReturnData(jobResult?.args?.returnData);
                            setJobResult(result);
                        }
                    } catch (error) {
                        console.error(`error fetching job result`)
                    }
                }
            }
        } catch (error) {
            console.error(`error fetching batch report data`)
        }
        setFetching(false)
    }

    const dispositionChart = useMemo(() => {
        let dispositionCategoryCounts = (jobResult && jobResult["dispositionCategoryCounts"]) || [];
        let categories = {};

        if (textContent && language) {
            let dispositionCategories = Object.values(textContent[language]);
            dispositionCategories = dispositionCategories.filter(text => DISPOSITION_CATEGORIES.includes(text.category));
            if (isArrayValid(dispositionCategories)) {
                dispositionCategories.forEach(c => {
                    categories[c.text] = 0;
                })
            }
        }
        Object.keys(dispositionCategoryCounts).forEach((category) => {
            if (!categories[category]) {
                categories[category] = 0;
            }
        })


        let sum = 0;
        let alertLevels = [];
        let entries = Object.entries(dispositionCategoryCounts);
        entries.forEach(([category, {total = 0, alertCounts}]) => {
            if (alertCounts)
                alertLevels = alertLevels.concat(Object.keys(alertCounts));
            if (category) {
                if (!categories[category]) {
                    categories[category] = 0;
                }
                let count = 0;
                if (dispositionAlertLevel === "all") {
                    count = total;
                } else if (alertCounts) {
                    count = alertCounts[dispositionAlertLevel] || 0;
                }
                if (category) {
                    sum += count;
                    categories[category] = count;
                }
            }
        })

        alertLevels = Array.from(new Set(alertLevels));
        setDispositionAlertLevels(["all", ...alertLevels]);
        categories = orderBy(Object.entries(categories), ([category, count]) => category).reverse();

        let databar = [{ name: "counts", data: categories.map(c => c[1]) }];

        const options = {
            theme: theme.overrides?.ApexChart,
            subtitle: {
                text: `Total notes with disposition: ${sum}`,
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    color: '#9699a2'
                },
            },
            chart: {
                height: "auto",
                id: 'checkinByHourCounts',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '50%',
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            yaxis: {
                title: {
                    text: 'Count',
                },
            },
            xaxis: {
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                    minHeight: "200"
                },
                categories: categories.map(c => c[0]),
                tickPlacement: 'on',
            },
            stroke: {
                width: 2
            },
            grid: {
                row: {
                    colors: ['#fff', '#f2f2f2']
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                enabled: false
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [0, 100]
                },
            },
            colors: ["#00838f", "#00695c"]
        }

        return <Chart options={options} series={databar} type="bar" height="500" style={{ width: "100%" }} />

    }, [jobResult, dispositionAlertLevel, textContent, language, theme])


    const labAlertCountsChart = useMemo(() => {
        return jobResult?.labAlertCounts?.length > 0 ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.labAlertCounts.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);

    const checkInAlertCountsChart = useMemo(() => {
        return jobResult?.proAlertCounts?.length > 0 ? <TableContainer >
            < Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.proAlertCounts.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table >
        </TableContainer > : <div>None</div>;


    }, [jobResult, theme]);

    const croAlertCountsChart = useMemo(() => {
        return jobResult?.croAlertCounts?.length > 0 ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.croAlertCounts?.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);

    const verifiedAlertCountsChart = useMemo(() => {
        return jobResult?.clinVerifiedAlertCounts?.length > 0 ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {jobResult?.clinVerifiedAlertCounts?.map((alert) =>
                        <TableRow><TableCell style={{ backgroundColor: alert.color }}>{alert?.name}</TableCell><TableCell>{alert?.count}</TableCell></TableRow >
                    )}

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);


    const dataCategoryTable = useMemo(() => {
        return jobResult ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    <TableRow><TableCell >Patient Reported</TableCell><TableCell>{jobResult?.proAlertCount}</TableCell></TableRow >
                    <TableRow><TableCell >Clinician Reported</TableCell><TableCell>{jobResult?.croAlertCount}</TableCell></TableRow >
                    <TableRow><TableCell >Clinician Verified</TableCell><TableCell>{jobResult?.clinVerifiedAlertCount}</TableCell></TableRow >
                    <TableRow><TableCell >Observer Reported</TableCell><TableCell>{jobResult?.obsAlertCount}</TableCell></TableRow >
                    <TableRow><TableCell >Lab Reported</TableCell><TableCell>{jobResult?.labAlertCount}</TableCell></TableRow >

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);

    const basicMetricsTable = useMemo(() => {
        return jobResult ? <TableContainer >
            <Table size="small" className={classes.cleanRow}>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    <TableRow><TableCell >{PATIENT_TEXT}</TableCell><TableCell>{jobResult?.totalPatients}</TableCell></TableRow >
                    <TableRow><TableCell >{PROVIDER_TEXT}</TableCell><TableCell>{jobResult?.totalProviders}</TableCell></TableRow >
                    <TableRow><TableCell >Organizations</TableCell><TableCell>{jobResult?.totalOrganizations}</TableCell></TableRow >

                </TableBody>
            </Table>
        </TableContainer> : <div>None</div>;

    }, [jobResult, theme]);


    console.debug(`Disposition alert levels `, dispositionAlertLevels)
    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <div className={classes.left}>
                    <div>
                        {!jobResult && currentJob?.status !== "active" && !fetching && <Typography color="primary" variant="subtitle1">
                            {T.translate("noRecentMetrics")}
                        </Typography>
                        }
                        {mostRecentCompletedJob && <Typography color="primary" variant="subtitle1">
                            {T.translate("metricsAsOf")} {moment(mostRecentCompletedJob.endTime).format(dateFormat)}
                        </Typography>
                        }
                        {currentJob?.status === "active" && <div style={{ marginLeft: "1rem" }}><ProgressText text={"updating"} /></div>
                        }
                    </div>
                    <div className={classes.search} style={{ marginBottom: "-10px" }}>
                        <DateRange
                            format={"MM/dd/yyyy"}
                            startTime={startTime}
                            endTime={endTime}
                            endTimeChange={(endTime) => setEndTime(moment(endTime).endOf("day"))}
                            startTimeChange={(startTime) => setStartTime(moment(startTime).startOf("day"))}
                        />
                        <FormControlLabel
                            color="primary"
                            labelPlacement="left"
                            style={{ width: '11rem', margin: 2 }}
                            control={
                                <Checkbox
                                    color="primary"
                                    disabled={fetching}
                                    checked={returnData}
                                    onChange={(e, v) => { setReturnData(v) }}
                                />
                            }
                            label="Include Raw Data"
                        />
                    </div>
                </div>
                <div className={classes.right}>

                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={fetching}
                        onClick={() => startNewJob()}>
                        <T.span text="regenerate" />
                    </Button>
                    <IconButton
                        color="primary"
                        disabled={fetching}
                        onClick={() => setShowCode(true)}>
                        <Code />
                    </IconButton>
                    <IconButton
                        color="primary"
                        style={{ marginRight: '1rem' }}
                        disabled={fetching}
                        onClick={() => { internalFetchJobs() }}>
                        {fetching ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <div>
                <Page  >
                    {jobResult &&
                        <InfoCardGrid >
                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={basicMetricsTable}

                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-users" />}
                                    iconColor='green'
                                />
                            </InfoCard>

                            <InfoCard
                                size="sm"
                                note={<div>{get(jobResult, "uniquePatientCheckinCount")} unique patient check-ins</div>}
                                instructions={<ul className={classes.noBulletList}>
                                    <li>Patient Reported: A check-in performed by the patient</li>
                                    <li>Clinician Reported: A check-in performed <strong>only</strong> by the provider</li>
                                    <li>Clinician Verfied: A patient check-in <strong>verified</strong> by the provider with <strong>contact</strong> with the patient</li>
                                    <li>Observer Reported: A check-in by a delegate</li>
                                    <li>Lab Reported: Alert generated from a lab import</li>
                                </ul>}
                            >
                                <SimpleCardInfo
                                    data={dataCategoryTable}
                                    text={"Longitudinal Categories"}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-user-doctor" />}
                                    iconColor='teal'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={checkInAlertCountsChart}
                                    text={`Patient Reported Alerts `}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-badge-check" />}
                                    iconColor='indigo'
                                />
                            </InfoCard>
                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={croAlertCountsChart}
                                    text={`Clinician Reported Alerts`}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-badge-check" />}
                                    iconColor='indigo'
                                />
                            </InfoCard>

                            <InfoCard size="sm">
                                <SimpleCardInfo
                                    data={verifiedAlertCountsChart}
                                    text={`Clinician Verified Alerts`}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-badge-check" />}
                                    iconColor='indigo'
                                />
                            </InfoCard>

                            {!isRTW && <InfoCard
                                size="sm"
                            >
                                <SimpleCardInfo
                                    data={labAlertCountsChart}
                                    text={`Lab Alert Counts`}
                                    icon={<FontAwesomeIcon className={classes.icon} icon="fa-regular fa-flask" />}
                                    iconColor='orange'
                                />
                            </InfoCard>}

                            <InfoCard size="xl">
                                <InfoCardBanner>
                                    <Typography variant="h6"></Typography>
                                    <InfoCardTitle>Disposition Distribution</InfoCardTitle>
                                    <Select value={dispositionAlertLevel}
                                        onChange={(e) => setDispositionAlertLevel(e.target.value)}
                                        style={{ textAlign: 'left', width: 100 }}
                                    >
                                        {isArrayValid(dispositionAlertLevels) && dispositionAlertLevels.map((level) => <MenuItem value={level}>{level.charAt(0).toUpperCase() + level.slice(1)}</MenuItem>)}
                                    </Select>
                                </InfoCardBanner>
                                {dispositionChart}
                            </InfoCard>

                        </InfoCardGrid>
                    }
                </Page>
            </div>
            <Dialog
                model="true"
                maxWidth={'md'}
                fullWidth={true}
                open={showCode}
                onClose={() => setShowCode(false)}
            >

                <DialogTitle>Longitudinal Metrics</DialogTitle>
                <DialogContent>
                    <Paper key="2" className={classes.jsonPaper}>
                        <ReactJson
                            src={{ jobResult, currentJob }}
                            theme={theme?.overrides?.ReactJsonView}
                            collapsed={3}
                            name="Metrics"
                            collapseStringsAfterLength={64}
                            displayDataTypes={false} />
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setShowCode(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default withStyles(styles)(LongitudinalPage)
